//导入axios和router

import axios from "axios";


//引用elenmentui的组件

import { Message } from 'element-ui'

import { Loading } from 'element-ui'

let loadingInstance = null

// 全局配置axios，并使用ajax作为别名

const instance = axios.create({
    timeout: 3000,

    responseType: 'json',

    //公共地址
    baseURL: "/jeecg-boot",

});

// 添加请求拦截器

instance.interceptors.request.use(function (config) {

    // 在发送请求之前做些什么

    loadingInstance = Loading.service({ fullscreen: true })

    // 配置 Token 认证
    config.headers['X-Access-Token'] = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE3MDg4ODE4NjksInVzZXJuYW1lIjoiYWRtaW4ifQ.OrnkNQe7uUBlD3k-_1EzF21NXi38pyPYkvGFs0EwBFU'
    console.log(config)

    return config;

}, function (error) {

    // 对请求错误做些什么

    return Promise.reject(error);

});

//添加响应拦截器

instance.interceptors.response.use(function (response) {

    // 2xx 范围内的状态码都会触发该函数。

    // 对响应数据做点什么

    loadingInstance.close()

    return response;

}, function (error) {

    // 超出 2xx 范围的状态码都会触发该函数。

    // 对响应错误做点什么

    if (error.response.status === 401) {

        // 无效的 token

        // 把 Vuex 中的 token 重置为空，并跳转到登录页面


        // 弹窗提示

        Message.error('用户身份已过期，请重新登录!')

    }

    return Promise.reject(error);

});

export default instance
